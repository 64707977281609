import {
  getCDNUrl,
  navigateToUrl,
  getEnvironment,
  getBrandingEnvironment,
  DEVELOPMENT_ENVIRONMENT,
  INTEGRATION_ENVIRONMENT,
  STAGING_ENVIRONMENT,
  PRODUCTION_ENVIRONMENT,
  WHITELABEL_ENVIRONMENT,
  getWebUrl,
  getAuthUrl,
  getWorkflowUrl,
  getSkymonkeyUrl,
  getAPIUrl,
} from "./canopy-urls.helpers";

function bootstrap(configUrl = "/canopy-urls.config.json") {
  /* We used to have a way for Dev Ops to configure which backends we talk to on each
   * environment by looking up a json file at runtime. We removed that for performance
   * and because it isn't being used anymore.
   *
   * Also we now have a feature toggle for the web gateway, which should be a temporary thing
   */
  return Promise.resolve();
}

export default {
  //backend urls
  getWebUrl,
  getAuthUrl,
  getWorkflowUrl,
  getSkymonkeyUrl,
  getAPIUrl,
  getCDNUrl,

  // navigation between ui apps
  navigateToUrl,

  //get environment
  getEnvironment,
  getBrandingEnvironment,
  DEVELOPMENT_ENVIRONMENT,
  INTEGRATION_ENVIRONMENT,
  STAGING_ENVIRONMENT,
  PRODUCTION_ENVIRONMENT,
  WHITELABEL_ENVIRONMENT,

  //bootstrap for non-prod environments
  bootstrap,
};

export {
  bootstrap,
  mount,
  unmount,
  showPageNotFound,
} from "./page-not-found.app.js";
export { ShowPageNotFoundComponent } from "./show-page-not-found.component.js";
export { getSofePath } from "./sofe-urls.helper.js";
