import React from "react";
import { showPageNotFound } from "./page-not-found.app.js";

export class ShowPageNotFoundComponent extends React.Component {
  componentDidMount() {
    showPageNotFound();
  }
  render() {
    /* This component doesn't render anything, it just makes sure that the
     * single spa app for page not found is activated.
     */
    return null;
  }
}
