import { getServiceUrl } from "sofe";

export { getSofePath };

function getSofePath(serviceName) {
  const serviceUrl = getServiceUrl(serviceName);
  const localDomain =
    localStorage.getItem("sofe:local-domain") || "https://localhost";

  if (parseInt(serviceUrl, 10) + "" === serviceUrl) {
    // The URL of the service is an override to localhost on a particular port
    return `${localDomain}:${serviceUrl}/`;
  }

  return serviceUrl.substring(0, serviceUrl.lastIndexOf("/") + 1);
}
